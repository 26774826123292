import { EActionButtonType } from './types';

const QUERY_ENDPOINTS_PROMOTIONS = {
  promotionTypes: '/promotion/types?user_id=[userId]',
  promotionCategories: '/promotion/categories?user_id=[userId]',
  promotionItems: '/promotion/items?user_id=[userId]',
  promotionItem: '/promotion/item?user_id=[userId]&promotion_id=[promotionId]',
  promotionCollectionListItems: '/promotion/collections?user_id=[userId]',
  promotionCollectionItem: '/promotion/collection?user_id=[userId]&collection_id=[collectionId]',
  promotionCatalog: '/promotion/catalog?user_id=[userId]',
  promotionVisualization: '/promotion/item/vizualization?user_id=[userId]',
  promotionVisualizationWithId:
    '/promotion/item/vizualization?user_id=[userId]&promotion_id=[promotionId]',
};

const QUERY_ENDPOINTS_BANNERS = {
  bannerScenarios: '/banners/scenarios?user_id=[userId]',
  bannerList: '/banners/list?user_id=[userId]&scenario_id=[scenarioId]',
  banner: '/banner?user_id=[userId]&banner_id=[bannerId]&scenario_id=[scenarioId]',
};

const QUERY_ENDPOINTS_NOTIFICATION_TASKS = {
  notificationTaskList: '/admin/notification/task/list?user_id=[userId]',
  notificationTask: '/admin/notification/task?user_id=[userId]&task_id=[taskId]',
};

export const QUERY_ENDPOINTS = {
  services: '/services?user_id=[userId]',
  scenarios: '/story/scenarios?user_id=[userId]',
  stories: '/story/sequence?story_scenario=[storyScenario]&user_id=[userId]',
  story: '/story?external_story_id=[externalStoryId]&user_id=[userId]',
  clientSettings: '/client/settings?user_id=[userId]',
  promotions: QUERY_ENDPOINTS_PROMOTIONS,
  banners: QUERY_ENDPOINTS_BANNERS,
  notificationTasks: QUERY_ENDPOINTS_NOTIFICATION_TASKS,
};

export const MUTATION_ENDPOINTS_PROMOTIONS = {
  savePromotion: '/promotion/item?user_id=[userId]&action=[action]',
  updatePromotion: '/promotion/item?user_id=[userId]&action=[action]&promotion_id=[promotionId]',
  massAction: '/promotions/action?user_id=[userId]&action=[action]',
  savePromotionCollectionListItemsOrder: '/promotion/collections?user_id=[userId]',
  saveExistingPromotionCollectionItem:
    '/promotion/collection?user_id=[userId]&collection_id=[collectionId]&version=[version]',
  saveNewPromotionCollectionItem: '/promotion/collection?user_id=[userId]',
  archivePromotionCollectionItem:
    '/promotion/collection/archive?collection_id=[collectionId]&user_id=[userId]',
  setPromotionCatalogUrl: '/promotion/catalog?user_id=[userId]',
};

export const MUTATION_ENDPOINTS_BANNERS = {
  createBanner: '/banner?user_id=[userId]&scenario_id=[scenarioId]',
  saveBanner: '/banner?user_id=[userId]&banner_id=[bannerId]&scenario_id=[scenarioId]',
  saveBannerList: '/banners/list?user_id=[userId]&scenario_id=[scenarioId]',
};

export const MUTATION_ENDPOINTS_NOTIFICATIONS = {
  saveExistingNotification:
    '/admin/notification/task?user_id=[userId]&action=[action]&task_id=[taskId]&version=[version]',
  saveNewNotification: '/admin/notification/task?user_id=[userId]&action=[action]',
};

export const MUTATION_ENDPOINTS = {
  login: '/login',
  saveStory: '/story?user_id=[userId]',
  storySequence: '/story/sequence?user_id=[userId]',
  uploadImage: '/upload/file?user_id=[userId]&type=image',
  uploadVideo: '/upload/file?user_id=[userId]&type=video',
  saveClientSettings: '/client/settings?user_id=[userId]&version=[version]',
  promotions: MUTATION_ENDPOINTS_PROMOTIONS,
  banners: MUTATION_ENDPOINTS_BANNERS,
  notificationTasks: MUTATION_ENDPOINTS_NOTIFICATIONS,
};

export const ROUTES = {
  root: '/',
  settings: '/settings',
  story: '/service/Мобильные приложения/module/Сценарии/scenario/:scenario/story/:story',
  service: '/service/:service',
  scenarios: '/service/Мобильные приложения/module/Сценарии',
  promotions: '/service/Мобильные приложения/module/Акции',
  scenario: '/service/Мобильные приложения/module/Сценарии/scenario/:scenario',
  slide:
    '/service/Мобильные приложения/module/Сценарии/scenario/:scenario/story/:story/slide/:slide',
  banners: '/service/Мобильные приложения/module/Баннеры',
  bannerScenario: '/service/Мобильные приложения/module/Баннеры/scenario/:scenario',
  banner: '/service/Мобильные приложения/module/Баннеры/scenario/:scenario/banner/:banner',
  notificationTasks: '/service/Мобильные приложения/module/Отправка пушей',
  notificationTask:
    '/service/Мобильные приложения/module/Отправка пушей/notification/:notification',
  module: '/service/:service/module/:module',
  login: '/login',
  selectClient: '/select-client',
};

export const MS_IN_ONE_SECOND = 1000;

export const actionButtonOptionValues = [
  EActionButtonType.close,
  EActionButtonType.deepLink,
  EActionButtonType.webview,
  EActionButtonType.browser,
  EActionButtonType.share,
];

export const actionButtonOptionTexts = ['Закрыть', 'Диплинк', 'Веб вью', 'Браузер', 'Шэар'];
