import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { TRootState, useAppDispatch } from '@/store';
import { ServiceNotAvailable } from '@/features/modules/components/ServiceNotAvailable';
import { authorizedPagesLayout } from '@/layouts/authorizedPagesLayout';
import { ModulesHeading, Module, Modules as ModulesWrapper, ModulesContainer } from './elements';
import {
  setMiddleMenuActiveBtn,
  setMiddleMenuOptions,
} from '@/features/sidebars/redux/services.slice';
import { ROUTES } from '@/shared/lib/const';
import { IModuleRecord } from '@/features/sidebars/types';
import { fetchPromotionItems } from '@/features/promotions/redux/promotions.actions';
import { fetchNotificationTaskList } from '@/features/notificationTasks/redux/notificationTasks.actions';
import { fetchBannerScenarios } from '@/features/banners/redux/scenarios.actions';
import { fetchScenarios } from '@/features/stories/redux/stories.actions';

const Modules = () => {
  const { service: serviceName } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const modules = useSelector((state: TRootState) => state.modules.modules);
  const services = useSelector((state: TRootState) => state.services.services);
  const serviceDisabled = useSelector((state: TRootState) => state.modules.disabled);
  const middleMenuOptions = useSelector((state: TRootState) => state.services.middleMenuOptions);

  useEffect(() => {
    dispatch(setMiddleMenuOptions(services));
  }, [services]);

  useEffect(() => {
    if (serviceName) {
      dispatch(setMiddleMenuActiveBtn(serviceName));
    }
  }, [middleMenuOptions, serviceName]);

  const handleModuleClick = (module: IModuleRecord) => {
    if (serviceName === 'Мобильные приложения') {
      let dispatchPromise;
      if (module.name === 'Сценарии') {
        dispatchPromise = dispatch(fetchScenarios());
      } else if (module.name === 'Акции') {
        dispatchPromise = dispatch(fetchPromotionItems());
      } else if (module.name === 'Баннеры') {
        dispatchPromise = dispatch(fetchBannerScenarios());
      } else if (module.name === 'Отправка пушей') {
        dispatchPromise = dispatch(fetchNotificationTaskList());
      }
      dispatchPromise.unwrap().then(() => {
        dispatch(setMiddleMenuOptions(modules));
        navigate(ROUTES.module.replace(':service', serviceName).replace(':module', module.name));
      });
    } else {
      dispatch(setMiddleMenuOptions(modules));
      navigate(ROUTES.module.replace(':service', serviceName).replace(':module', module.name));
    }
  };

  return (
    <>
      {serviceDisabled ? (
        <ServiceNotAvailable />
      ) : (
        <ModulesContainer>
          <ModulesHeading>Доступные модули</ModulesHeading>
          <ModulesWrapper>
            {modules?.map((module) => {
              return (
                <Module key={module.id} onClick={() => handleModuleClick(module)}>
                  {module.name}
                </Module>
              );
            })}
          </ModulesWrapper>
        </ModulesContainer>
      )}
    </>
  );
};

export default () => authorizedPagesLayout({ Content: Modules });
