import { useParams } from 'react-router-dom';

import Scenarios from '@/features/stories/scenarios';
import { authorizedPagesLayout } from '@/layouts/authorizedPagesLayout';

const Module = () => {
  const { module } = useParams();
  return <>{module}</>;
};

export default () => authorizedPagesLayout({ Content: Module });
