import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import { styledTheme } from './styled-theme';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

// Create a theme instance.
const muiTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#7b9efc',
    },
    error: {
      main: red.A400,
    },
    // @ts-ignore: Unreachable code error
    lightGrey: createColor('rgba(255, 255, 255, 0.23)'),
    buttonMain: createColor('#4b7cfc'),
    textMain: createColor(styledTheme.colors.text.main),
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

export default muiTheme;
