import { FC, useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { FormikErrors } from 'formik';

export enum ECustomSelectPredefinedOptionValues {
  notSet = '-1',
}

export enum ECustomSelectPredefinedOptionTexts {
  notSet = 'Не задана',
}

interface CustomSelectProps {
  optionValues: (number | string)[];
  optionTexts: string[];
  label: string;
  value?: string | number;
  onChange?: (value: string) => void;
  disabled?: boolean;
  sx?: Record<string, string | number>;
  error?: boolean;
  helperText?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
}

export const CustomSelect: FC<CustomSelectProps> = ({
  optionValues: optionValuesProps,
  optionTexts: optionTextsProps,
  label: inputLabel,
  value: valueProp,
  onChange: onChangeProps,
  disabled,
  sx,
  error,
  helperText,
}) => {
  const [optionValues, setOptionValues] = useState<(string | number)[]>([]);
  const [optionTexts, setOptionTexts] = useState<string[]>([]);
  const [value, setValue] = useState<string | number>();

  useEffect(() => {
    setOptionValues([ECustomSelectPredefinedOptionValues.notSet, ...optionValuesProps]);
  }, [optionValuesProps]);

  useEffect(() => {
    setOptionTexts([ECustomSelectPredefinedOptionTexts.notSet, ...optionTextsProps]);
  }, [optionTextsProps]);

  useEffect(() => {
    let nextValue = valueProp;
    if (!nextValue) {
      nextValue = ECustomSelectPredefinedOptionValues.notSet;
    }
    setValue(nextValue as number);
  }, [valueProp]);

  // Описание: иногда MUI компонент Select не обновляет текущее значение,
  // когда поле value поменялось. Код ниже исправляет эту проблему.
  const selectKey: number = useMemo(() => {
    return Math.random();
  }, [value]);

  const handleSelectChange = (event: SelectChangeEvent) => {
    let value = event.target.value;
    if (value === ECustomSelectPredefinedOptionValues.notSet) {
      value = null;
    }
    onChangeProps?.(value);
  };

  return (
    <FormControl fullWidth disabled={disabled} sx={{ flexGrow: 1, flexBasis: 0, ...sx }}>
      <InputLabel id="custom-select-label" size="small" error={error}>
        {inputLabel}
      </InputLabel>
      <Select
        key={selectKey}
        labelId="custom-select-label"
        id="custom-select"
        value={value?.toString()}
        label={inputLabel}
        onChange={handleSelectChange}
        size="small"
        disabled={disabled}
        error={error}
      >
        {optionValues.map((optionValue, index) => {
          return (
            <MenuItem key={index} value={optionValue?.toString()} sx={{ height: '36px' }}>
              {optionTexts[index]}
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText error={error}>{helperText as string}</FormHelperText>}
    </FormControl>
  );
};
