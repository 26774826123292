import { FC } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { MainContentWrapper } from '@/app/RoutesWrapper/elements';
import { MainMenu } from '@/features/sidebars/MainMenu';
import { MiddleMenu } from '@/features/sidebars/MiddleMenu';

interface IAuthorizedPagesLayoutProps {
  Content: FC;
  isShowMiddleMenu?: boolean;
}

export const authorizedPagesLayout: FC<IAuthorizedPagesLayoutProps> = ({
  Content,
  isShowMiddleMenu = true,
}) => {
  return (
    <>
      <MainMenu></MainMenu>
      {isShowMiddleMenu && <MiddleMenu></MiddleMenu>}
      <MainContentWrapper>
        <Scrollbars>
          <Content />
        </Scrollbars>
      </MainContentWrapper>
    </>
  );
};
