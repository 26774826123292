export const styledTheme = {
  colors: {
    background: {
      main: '#2b2a32',
      module: {
        main: '#3f3e46',
        hover: 'rgba(255, 255, 255, 0.2)',
        disabled: '#9c9c9f',
      },
    },
    borders: {
      main: '#494951',
    },
    text: {
      main: 'rgba(255, 255, 255, 0.8)',
      disabled: 'rgba(255, 255, 255, 0.2)',
      auth: 'rgba(234, 234, 250, 0.9)',
    },
    menu: {
      hover: 'rgba(255, 255, 255, 0.15)',
      active: 'rgba(125, 161, 252, 0.2)',
    },
    button: {
      main: {
        backgroundColor: '#4b7cfc',
        hoverBackgroundColor: '#608cfe',
        backgroundColorDisabled: '#82a4fd',
      },
      secondary: {
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
    textarea: {
      text: '#fff',
      hover: '#fff',
      placeholderText: 'rgba(255, 255, 255, 0.7)',
    },
    login: {
      button: {
        border: '#b0b6cb',
        text: '#b0b6cb',
        hover: 'rgba(85, 108, 214, 0.08)',
      },
      input: {
        textColor: 'rgba(247, 247, 255, .9)',
        border: '#b0b6cb',
        autocompleteBackground: 'rgba(59, 63, 73, 0.95)',
        borderHover: '#84a5f9',
      },
    },
    storyViewIndicators: {
      dark: '#686b6d',
    },
    error: '#e61a1a',
    lightGrey: 'rgba(255, 255, 255, 0.23)',
    promotionCollectionItem: {
      border: '#fff',
    },
    actionButton: {
      hover: '#fff',
    },
    fieldset: {
      text: {
        disabled: 'rgba(255 ,255, 255, 0.5)',
      },
    },
    input: {
      disabled: {
        labelText: 'rgba(255, 255, 255, 0.6)',
        inputText: 'rgba(255, 255, 255, 0.7)',
        background: 'rgba(255, 255, 255, 0.1)',
      },
    },
    authLayoutError: '#951f34',
  },
};
