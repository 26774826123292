import { FC, useEffect, useMemo } from 'react';
import { Stack, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

import { authorizedPagesLayout } from '@/layouts/authorizedPagesLayout';
import { SettingsContainer, SettingsHeading } from './elements';
import { MainButton } from '@/shared/components/MainButton';
import { TRootState, useAppDispatch } from '@/store';
import { fetchClientSettings, saveClientSettings } from './redux/clientSettings.actions';
import { EClientSettingsFieldKeys } from './types';
import { setFieldValueBasic } from './redux/clientSettings.slice';
import { StaticCodes } from './components/StaticCodes';
import { FilePath } from './components/FilePath';
import { PurchaseHistoryFilters } from './components/PurchaseHistoryFilters';
import { isClientSettingsTouched, isClientSettingsValid } from './utils';

const ClientSettings: FC = () => {
  const dispatch = useAppDispatch();
  const clientSettings = useSelector((state: TRootState) => state.clientSettings.clientSettings);
  const clientSettingsInitial = useSelector(
    (state: TRootState) => state.clientSettings.clientSettingsInitial
  );
  const staticCodesItemsInitial = useSelector(
    (state: TRootState) => state.clientSettings.staticCodesItemsInitial
  );
  const staticCodesItems = useSelector(
    (state: TRootState) => state.clientSettings.staticCodesItems
  );

  const isSaveBtnDisabled = useMemo(() => {
    return (
      !isClientSettingsTouched({
        clientSettings,
        clientSettingsInitial,
        staticCodesItems,
        staticCodesItemsInitial,
      }) || !isClientSettingsValid({ clientSettings, staticCodesItems })
    );
  }, [clientSettings, clientSettingsInitial, staticCodesItemsInitial, staticCodesItems]);

  const filePathField = useMemo(() => {
    const filePathFieldFound = clientSettings?.fields.find((field) => {
      return field.key === EClientSettingsFieldKeys.filePath;
    });

    return filePathFieldFound;
  }, [clientSettings]);

  const purchaseHistoryFiltersField = useMemo(() => {
    const purchaseHistoryFiltersFieldFound = clientSettings?.fields.find((field) => {
      return field.key === EClientSettingsFieldKeys.purchaseHistoryFilters;
    });

    return purchaseHistoryFiltersFieldFound;
  }, [clientSettings]);

  const handlePartnerIdValueChange = ({
    event,
    key,
  }: {
    event: React.ChangeEvent<HTMLInputElement>;
    key: EClientSettingsFieldKeys | string;
  }) => {
    dispatch(setFieldValueBasic({ key, value: event.target.value }));
  };

  const handleSaveBtnClick = () => {
    dispatch(saveClientSettings());
  };

  useEffect(() => {
    dispatch(fetchClientSettings());
  }, [dispatch]);

  return (
    <SettingsContainer>
      <SettingsHeading>Настройки</SettingsHeading>
      <SettingsHeading>Настройки организации</SettingsHeading>
      <Stack sx={{ alignItems: 'center', width: '500px' }}>
        {filePathField && filePathField.fields.length > 0 && <FilePath />}
        {purchaseHistoryFiltersField && purchaseHistoryFiltersField.fields.length > 0 && (
          <PurchaseHistoryFilters />
        )}
        {clientSettings?.fields.map((field) => {
          const key = field.key;

          if (
            key !== EClientSettingsFieldKeys.filePath &&
            key !== EClientSettingsFieldKeys.purchaseHistoryFilters &&
            key !== EClientSettingsFieldKeys.staticCodes
          ) {
            return (
              <TextField
                key={field.key}
                sx={{ width: '100%', mb: 2 }}
                label={field.name}
                type="text"
                size="small"
                value={field.value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handlePartnerIdValueChange({ event, key: field.key })
                }
              />
            );
          }
          return null;
        })}

        <StaticCodes />

        <MainButton
          sx={{ width: '150px', mt: 1 }}
          disabled={isSaveBtnDisabled}
          onClick={handleSaveBtnClick}
        >
          Сохранить
        </MainButton>
      </Stack>
    </SettingsContainer>
  );
};

export default () => authorizedPagesLayout({ Content: ClientSettings, isShowMiddleMenu: false });
