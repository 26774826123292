import { Routes, Route } from 'react-router-dom';

import Modules from '@/features/modules/components/Modules';
import { ROUTES } from '@/shared/lib/const';
import Login from '@/features/auth/login';
import ClientList from '@/features/auth/clientList';
import Module from '@/features/module/components/Module';
import StoryScenarios from '@/features/stories/scenarios';
import BannerScenarios from '@/features/banners/scenarios';
import BannerScenario from '@/features/banners/scenario';
import StoryScenario from '@/features/stories/scenario';
import { authorizedPagesLayout } from '@/layouts/authorizedPagesLayout';
import Story from '@/features/stories/story';
import Slide from '@/features/stories/slide';
import Promotions from '@/features/promotions';
import ClientSettings from '@/features/sidebars/MainMenu/ClientSettings';
import Banner from '@/features/banners/banner';
import NotificationTasks from '@/features/notificationTasks/notificationTasks';
import NotificationTask from '@/features/notificationTasks/notificationTask';

export const RoutesWrapper = () => (
  <Routes>
    <Route path="/" element={<Modules />} />
    <Route path={ROUTES.service} element={<Modules />} />
    <Route path={ROUTES.slide} element={authorizedPagesLayout({ Content: Slide })} />
    <Route path={ROUTES.story} element={authorizedPagesLayout({ Content: Story })} />
    <Route path={ROUTES.scenarios} element={authorizedPagesLayout({ Content: StoryScenarios })} />
    <Route path={ROUTES.scenario} element={authorizedPagesLayout({ Content: StoryScenario })} />
    <Route path={ROUTES.promotions} element={authorizedPagesLayout({ Content: Promotions })} />
    <Route path={ROUTES.module} element={<Module />} />
    <Route path={ROUTES.login} element={<Login />} />
    <Route path={ROUTES.selectClient} element={<ClientList />} />
    <Route path={ROUTES.settings} element={<ClientSettings />} />
    <Route path={ROUTES.banners} element={authorizedPagesLayout({ Content: BannerScenarios })} />
    <Route
      path={ROUTES.bannerScenario}
      element={authorizedPagesLayout({ Content: BannerScenario })}
    />
    <Route path={ROUTES.banner} element={authorizedPagesLayout({ Content: Banner })} />
    <Route
      path={ROUTES.notificationTasks}
      element={authorizedPagesLayout({ Content: NotificationTasks })}
    />
    <Route
      path={ROUTES.notificationTask}
      element={authorizedPagesLayout({ Content: NotificationTask })}
    />
  </Routes>
);
