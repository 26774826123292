import { combineReducers } from '@reduxjs/toolkit';

import services from '@/features/sidebars/redux/services.slice';
import modules from '@/features/modules/redux/modules.slice';
import authentication from '@/features/auth/redux/authentication.slice';
import stories from '@/features/stories/redux/stories.slice';
import promotions from '@/features/promotions/redux/promotions.slice';
import clientSettings from '@/features/sidebars/MainMenu/ClientSettings/redux/clientSettings.slice';
import bannerScenarios from '@/features/banners/redux/scenarios.slice';
import notificationTasks from '@/features/notificationTasks/redux/notificationTasks.slice';

const reducers = combineReducers({
  authentication,
  services,
  modules,
  stories,
  promotions,
  clientSettings,
  bannerScenarios,
  notificationTasks,
});

export default reducers;
