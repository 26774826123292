import { AppContainer } from '@/app/elements';
import { RoutesWrapper } from '@/app/RoutesWrapper';

function App() {
  return (
    <>
      <AppContainer>
        <RoutesWrapper></RoutesWrapper>
      </AppContainer>
    </>
  );
}

export default App;
