import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AxiosError } from 'axios';
import Box from '@mui/material/Box';

import { LoginBtn, Heading, Logo, MainContent, ErrorMessage } from './elements';
import { unauthorizedPagesLayout } from '@/layouts/unauthorizedPagesLayout';
import { StyledTextField } from '../shared/StyledTextField';
import { StyledOutlinedPasswordInput } from '../shared/StyledOutlinedPasswordInput';
import { useAppDispatch } from '@/store';
import { login } from '../redux/authentication.actions';
import { ILogin404Error, ILoginResponse } from '../types';
import { ROUTES } from '@/shared/lib/const';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLoginBtnClick = () => {
    dispatch(login({ login: email, password }))
      .unwrap()
      .then((responseData) => {
        const userId = (responseData as ILoginResponse).data.user.id.toString();
        Cookies.set('userId', userId, {
          path: '',
          domain: import.meta.env.VITE_COOKIE_DOMAIN,
        });
        const clients = (responseData as ILoginResponse).data.clients;
        const client = (responseData as ILoginResponse).data.client;
        if (!!clients && clients.length > 1) {
          navigate(ROUTES.selectClient);
        } else {
          const clientUrl = (clients?.[0] || client).client_url;
          if (window.location.origin + '/' === clientUrl) {
            navigate(ROUTES.root);
          } else {
            window.location.assign(clientUrl);
          }
        }
      })
      .catch((error) => {
        const clients = (error as AxiosError<ILogin404Error>).response?.data?.data?.clients;
        const status = (error as AxiosError<ILogin404Error>).response?.status;
        if (!!clients && status === 404) {
          const userId = (
            error as AxiosError<ILogin404Error>
          ).response?.data?.data?.user?.id?.toString();
          Cookies.set('userId', userId, {
            path: '',
            domain: import.meta.env.VITE_COOKIE_DOMAIN,
          });
          if (clients.length > 1) {
            navigate(ROUTES.selectClient);
          } else {
            window.location.assign(clients[0].client_url);
          }
        } else {
          setIsError(true);
          setErrorMessage(error.response.data.error.user_message);
        }
      });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsError(false);
    setErrorMessage('');
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setIsError(false);
    setErrorMessage('');
  };

  return (
    <MainContent>
      <Logo />
      <Heading>Авторизуйтесь</Heading>
      <Box
        component="form"
        sx={{
          '& > :not(style):not(:last-child)': { mb: 2 },
        }}
        noValidate
        autoComplete="off"
      >
        <StyledTextField
          label="Почта"
          color="secondary"
          autoComplete="off"
          variant="outlined"
          type="email"
          error={isError}
          value={email}
          onChange={handleEmailChange}
        />
        <StyledOutlinedPasswordInput
          label="Введите пароль"
          color="secondary"
          type="password"
          autoComplete="off"
          error={isError}
          value={password}
          onChange={handlePasswordChange}
        />
        {isError && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <LoginBtn size="large" variant="outlined" onClick={handleLoginBtnClick}>
          Войти
        </LoginBtn>
      </Box>
    </MainContent>
  );
};

export default () => unauthorizedPagesLayout(Login);
